import algoliasearch, { SearchClient } from 'algoliasearch/lite'
import { isEmpty } from 'lodash'
import { MultipleQueriesQuery } from '@algolia/client-search'

export default class ClientFactory {
    private static instance: SearchClient | null = null

    public static getSearchClient(context): SearchClient {
        if (this.instance) {
            return this.instance
        }
        this.instance = algoliasearch(
            context.project.configuration.algolia.appId,
            context.project.configuration.algolia.appKey,
        )
        return this.instance
    }

    public static getColors(context, modalKeys) {
        const { indexName } = context.project.configuration.algolia.languages[context.locale]
        const colorRequest = [
            {
                indexName,
                params: {
                    hitsPerPage: 800,
                    analytics: false,
                    distinct: true,
                    facetFilters: [
                        modalKeys,
                    ],
                },
            },
        ]
        return this.getSearchClient(context).search(colorRequest)
    }

    public static getAttributeValueByFiltersParam(productAttributes, filtersValue) {
        if (!productAttributes) {
            return ''
        }

        const attributesToSearch = [
            'attributes.att_prod_gender',
            'attributes.att_prod_fedas_field',
            'attributes.att_prod_color_name_sport2000',
            'attributes.att_internal_category_online_shop',
            'brand',
        ]

        attributesToSearch.forEach((attribute) => {
            const hasAttributeInFiltersValue = filtersValue.includes(attribute)

            if (!hasAttributeInFiltersValue) {
                return
            }

            const formatAttrByProductAttr = attribute.replace(/attributes./g, '')
            const regexAttr = new RegExp(`${attribute}:\\s*sameAsView`, 'g')
            const attributeValue = attribute === 'brand' ? productAttributes.att_prod_brand : (productAttributes[formatAttrByProductAttr] || '')
            filtersValue = filtersValue.replace(regexAttr, `${attribute}:${JSON.stringify(attributeValue)}`)
        })

        return filtersValue
    }

    public static queryProductsForRecommendationSlider(context, recommendationConfigurations, productAttributes) {
        const { indexName } = context.project.configuration.algolia.languages[context.locale]
        const filtersValue = recommendationConfigurations.filters

        if (filtersValue) {
            recommendationConfigurations.filters = this.getAttributeValueByFiltersParam(productAttributes, filtersValue)
        }

        const productRequest = [
            {
                indexName,
                params: {
                    analytics: false,
                    distinct: true,
                    ...recommendationConfigurations,
                },
            },
        ]
        return this.getSearchClient(context).search(productRequest)
    }

    public static queryStore(context, query, options = {}, baseOptions = {}) {
        const { storeIndexName } = context.project.configuration.algolia.languages[context.locale]
        let analytics = true

        if (!query || query.length === 0) {
            analytics = false
        }

        const storeRequest = [
            {
                indexName: storeIndexName,
                params: {
                    distinct: true,
                    query,
                    analytics,
                    ...options,
                },
            },
            {
                indexName: storeIndexName,
                params: {
                    distinct: true,
                    analytics,
                    ...baseOptions, // Always get all facet filter to assure filter options are not updated
                },
            },
        ]

        return this.getSearchClient(context).search(storeRequest)
    }

    public static getCategories(context, query) {
        const { indexName } = context.project.configuration.algolia.languages[context.locale]
        const index = this.getSearchClient(context).initIndex(indexName)

        return index.searchForFacetValues('category_paths', query)
    }

    public static getSearchSuggestions(context, query, categoryId, requesOptions) {
        const {
            indexName,
            suggestionIndexName,
            brandIndexName,
            storeIndexName,
            blogIndexName,
        } = context.project.configuration.algolia.languages[context.locale]

        const searchSuggestionQueries = [
            {
                indexName,
                type: 'facet',
                facet: 'category_paths',
                params: {
                    facetQuery: query,
                },
            },
            {
                indexName: suggestionIndexName,
                query,
                params: {
                    hitsPerPage: 3,
                    clickAnalytics: true,
                    userToken: context.session.account.accountId,
                    page: 0,
                },
            },
            {
                indexName: brandIndexName,
                query,
                params: {
                    hitsPerPage: 3,
                    clickAnalytics: true,
                    userToken: context.session.account.accountId,
                    page: 0,
                },
            },
            {
                indexName: storeIndexName,
                query,
                params: {
                    hitsPerPage: 2,
                    clickAnalytics: true,
                    userToken: context.session.account.accountId,
                    page: 0,
                },
            },
            {
                indexName,
                query,
                params: {
                    ...((categoryId) && { filters: `categories:${categoryId}` }),
                    hitsPerPage: 6,
                    distinct: true,
                    clickAnalytics: true,
                    userToken: context.session.account.accountId,
                    page: 0,
                },
            },
            {
                indexName: blogIndexName,
                query,
                params: {
                    hitsPerPage: 2,
                    clickAnalytics: true,
                    userToken: context.session.account.accountId,
                    page: 0,
                },
            },
        ]

        return this.getSearchClient(context).search(searchSuggestionQueries as MultipleQueriesQuery[], requesOptions)
            .then((response) => ({
                category: response.results[0],
                suggestion: response.results[1],
                brand: response.results[2],
                store: response.results[3],
                product: response.results[4],
                blog: response.results[5],
            }))
    }

    public static getModelKeys = (initHits): string[] => {
        const modelKeys = []
        initHits.forEach((hit) => {
            modelKeys.push(`root_model_key: ${hit.root_model_key}`)
        })
        return modelKeys
    }

    public static getColorsByModelKey = (modelKey, hits): any[] => hits.filter((hit) => hit.root_model_key === modelKey)

    public static bindColors(hits, colors) {
        const result = [...hits]
        result.forEach((hit) => {
            const modelKey = hit.root_model_key
            const hitColors = colors.filter((color) => color.root_model_key === modelKey)
            const indexOfActive = hitColors.findIndex((hitColor) => hitColor.sku === hit.sku)
            hit.colors = hitColors
            if (indexOfActive !== -1 && hit.colors) {
                hit.colors[indexOfActive].isActive = true
            }
        })

        return result
    }

    public static querySearchSuggestion(context, query, options = {}) {
        const { suggestionIndexName } = context.project.configuration.algolia.languages[context.locale]

        let analytics = true

        if (!query || query.length === 0) {
            analytics = false
        }

        const searchSuggestionQuery = [
            {
                indexName: suggestionIndexName,
                params: {
                    distinct: true,
                    hitsPerPage: 6,
                    query,
                    analytics,
                    ...options,
                },
            },
        ]

        return this.getSearchClient(context).search(searchSuggestionQuery)
    }

    public static generateConfigure(settings) {
        const props = {}

        if (!settings) {
            return props
        }

        settings.forEach((setting) => {
            props[setting.prop] = setting.value
        })

        return props
    }

    public static getObjectIdWishlist = (listSku): string[] => {
        const objectId = []
        listSku.forEach((hit) => {
            objectId.push(`objectID: ${hit}`)
        })
        return objectId
    }

    public static getConfig(context) {
        return {
            appId: context.project.configuration.algolia.appId,
            searchApiKey: context.project.configuration.algolia.appKey,
            indexName: context.project.configuration.algolia.indexName,
            indexBrandName: context.project.configuration.algolia.brandIndexName,
            indexBlogName: context.project.configuration.algolia.blogIndexName,
            indexSuggestionName: context.project.configuration.algolia.suggestionIndexName,
            indexStoreName: context.project.configuration.algolia.storeIndexName,
        }
    }

    public static queryInformationCategory(refinementList, context, initialFilter, displaySidebarByResult) {
        const { indexName } = context.project.configuration.algolia.languages[context.locale]
        const facetFilters = []
        let facet = []

        if (!isEmpty(refinementList)) {
            for (const [key, values] of Object.entries(refinementList)) {
                (values as string[]).forEach((value) => {
                    facet.push(`${key}:${value}`)
                })

                facetFilters.push(facet)
                facet = []
            }
        }

        const categoryRequest = [
            {
                indexName,
                params: {
                    hitsPerPage: 0,
                    analytics: false,
                    distinct: true,
                    facetingAfterDistinct: true,
                    facets: ['categories'],
                    facetFilters,
                    maxValuesPerFacet: 5000,
                    ...((displaySidebarByResult || initialFilter.indexOf('gln_channels') > -1) && { filters: initialFilter }),
                },
            },
        ]

        return this.getSearchClient(context).search(categoryRequest)
    }
}
