import React from 'react'
import {FormattedMessage, injectIntl} from 'react-intl'
import {CartIcon} from '../../../../atoms/icon'

const EmptyMiniCart = () => (
    <div className={'mini-cart'}>
        <div className={'mini-cart--empty-state'}>
            <CartIcon width={32} height={30}/>
            <p className={'mt-2'}>
                <FormattedMessage id={'cart.empty'}/>
            </p>
        </div>
    </div>
)

export default injectIntl(EmptyMiniCart)
