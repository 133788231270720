import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import Button from '../../../../atoms/button/Button'
import IconButton from '../../../../atoms/button/IconButton'
import Price from '../../../../atoms/price'
import EmptyMiniCart from './EmptyMiniCart'
import { ReactComponent as CloseIcon } from '../../../../../../icons/sport2000-icons/close.svg'
import CartItem from './CartItem'
import ErrorMessageProduct from '../../../ErrorMessageProduct'

const Index = ({
    goToCartPage, cart, intl, closeMiniCart, goToCheckoutPage,
}) => {
    if (!cart || !cart.data) {
        return null
    }

    if (cart && cart.data && !cart.data.lineItems.length) {
        return <EmptyMiniCart />
    }

    const { newAddedProduct } = useSelector((state) => state['mini-cart'])

    const removeLabelSize = (item) => item.variant.attributes.attr_size

    const renderSummary = () => (
        <div className={'mini-cart--summary'}>
            <p className={'is--total'}>
                <FormattedMessage id={'miniCart.total'} />
                <br />
                <span className={'is--sub-total'}><FormattedMessage id={'miniCart.subTotal'} /></span>
                <Price value={cart.data.sum} />
            </p>
            <div className={'mini-cart--actions'}>
                <Button
                    className={'btn btn-default'}
                    onClick={(e) => {
                        e.stopPropagation()
                        goToCheckoutPage()
                    }}
                    ariaLabel={intl.formatMessage({ id: 'miniCart.checkout' })}
                >
                    <FormattedMessage
                        id={'miniCart.checkout'}
                    />
                </Button>
                <Button
                    className={'btn btn-secondary'}
                    onClick={(e) => {
                        e.stopPropagation()
                        goToCartPage()
                    }}
                    ariaLabel={intl.formatMessage({ id: 'miniCart.cart' })}
                >
                    <FormattedMessage
                        id={'miniCart.cart'}
                    />
                </Button>
            </div>
        </div>
    )

    return (
        <>
            <div className={'mini-cart--temp'}>
                {newAddedProduct && (
                    <div className={'mini-cart--list-item'}>
                        <>
                            <div className={'mini-cart--close'}>
                                <FormattedMessage
                                    id={'miniCart.close'}
                                />
                                <IconButton
                                    name={intl.formatMessage({ id: 'miniCart.close' })}
                                    ariaLabel={intl.formatMessage({ id: 'miniCart.close' })}
                                    icon={<CloseIcon />}
                                    onClick={closeMiniCart}
                                    type={'button'}
                                />
                            </div>
                            <CartItem
                                name={newAddedProduct.name}
                                image={newAddedProduct.images[0]}
                                color={newAddedProduct.attributes.color}
                                size={newAddedProduct.attributes.size}
                                price={newAddedProduct.price}
                                count={newAddedProduct.count}
                                discountedPrice={newAddedProduct.discountedPrice}
                            />
                        </>
                    </div>
                )}
                {renderSummary()}
            </div>

            <div className={'mini-cart'}>
                <div>
                    <ErrorMessageProduct
                        removedItems={cart.data?.projectSpecificData?.removedLineItems ?? []}
                        reducedLineItems={cart.data?.projectSpecificData?.reducedLineItems ?? []}
                    />
                </div>
                <div className={'mini-cart--list-item'}>
                    {cart.data.lineItems.map((item) => (
                        <CartItem
                            key={item.lineItemId}
                            name={item.name}
                            image={item.variant.images[0]}
                            color={item.variant.attributes.att_prod_color_name_sport2000}
                            size={removeLabelSize(item)}
                            count={item.count}
                            price={item.price}
                            discountedPrice={item.discountedPrice}
                            url={`${item.variant.projectSpecificData.productUrl}?sku=${item.variant.sku}`}
                        />
                    ))}
                </div>
                {renderSummary()}
            </div>
        </>
    )
}

Index.propTypes = {
    intl: PropTypes.object.isRequired,
    goToCartPage: PropTypes.func.isRequired,
    closeMiniCart: PropTypes.func.isRequired,
    goToCheckoutPage: PropTypes.func.isRequired,
    cart: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default injectIntl(Index)
