import React, { Dispatch, SetStateAction } from 'react'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { Hit } from 'react-instantsearch-core'

export type Props = {
    searchResult: any
    searchHitsOpen: boolean,
    setCurrentValue?: Dispatch<SetStateAction<string>>,
} & WrappedComponentProps

const SearchSuggestHits: React.FC<Props> = ({
    searchResult, setCurrentValue,
}: Props) => {
    if (!searchResult || !searchResult.hits || !searchResult.hits.length) {
        return null
    }

    const getValueButton = (e) => {
        setCurrentValue(e.currentTarget.value)
        e.preventDefault()
    }

    const { hits } = searchResult
    const indexName = searchResult.index

    return (
        <div className={'search--suggest search--common'}>
            <h4 className={'search-hits--headline'}>
                <FormattedMessage id={'search.searchHits.suggestHeadline'} />
            </h4>

            <div className={'search-hits--list search--common-wrapper'} data-insights-index={indexName}>
                {hits.map((hit: Hit) => (
                    <div
                        className={'relative search-suggest-hit search-insights-hit'}
                        key={hit.query}
                        data-insights-object-id={hit.objectID}
                        data-insights-position={hit.__position}
                        data-insights-query-id={hit.__queryID}
                    >
                        <div>
                            {hit.query && (
                                <button
                                    className={'search--suggest-content--item search--common-wrapper-content'}
                                    type={'button'}
                                    value={hit.query}
                                    onClick={(e) => {
                                        getValueButton(e)
                                    }}
                                >
                                    <span
                                        className={'ais-Highlight'}
                                        dangerouslySetInnerHTML={{ __html: hit._highlightResult.query.value }}
                                    />
                                </button>
                            )}
                        </div>
                        <div className={'search--common-wrapper-category absolute right-0 lg:hidden'}>
                            <FormattedMessage id={'search.searchHits.categorySuggest'} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default injectIntl(SearchSuggestHits)
