import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Helmet } from 'react-helmet'
import ComponentInjector from '@frontastic/catwalk/src/js/app/injector'
import {withRouter} from 'next/router';
import {getBaseUrl} from '@frontastic/catwalk/js/helper/getBaseUrl';

class OpenGraph extends Component {
    render() {
        const urlPage = getBaseUrl(this.props.router)
        const openGraphConfiguration = {
            title: this.props.node.configuration.seoTitle,
            image: this.props.node.configuration.image && this.props.node.configuration.image.media.file,
            description: this.props.node.configuration.seoDescription
        }

        return (
            <Helmet>
                {openGraphConfiguration.title && (
                    <meta property="og:title" content={openGraphConfiguration.title} />
                )}
                <meta property="og:type" content="website" />
                <meta property="og:url" content={urlPage} />
                {openGraphConfiguration.image && (
                    <meta property="og:image" content={openGraphConfiguration.image} />
                )}
                {openGraphConfiguration.description && (
                    <meta property="og:description" content={openGraphConfiguration.description} />
                )}
            </Helmet>
        )
    }
}

OpenGraph.propTypes = {
    node: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
}

OpenGraph.defaultProps = {}

export default withRouter(ComponentInjector.return('Node.Meta.OpenGraph', OpenGraph))
