import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import List from '../../molecules/List'

const SearchLinkLayout = ({
    searchFAQ,
}) => {
    const closeSearch = () => {
        const customEvent = new Event('onCloseSearch')

        document.dispatchEvent(customEvent)
    }

    if (!searchFAQ.length) {
        return null
    }

    return (
        <div className={'search--link-layout search--layout'}>
            <h4 className={'search-hits--headline'}>
                <FormattedMessage id={'search.searchHits.faqHeadline'} />
            </h4>
            <div className={'search--link-layout--content '}>
                <List
                    onClick={closeSearch}
                    links={searchFAQ}
                    variant={classnames('search--layout--content')}
                    itemVariant={classnames('search--layout--content-wrap')}
                />
            </div>
        </div>
    )
}

SearchLinkLayout.propTypes = {
    searchFAQ: PropTypes.array,
}

export default SearchLinkLayout
