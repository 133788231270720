import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import classnames from 'classnames'
import { Cookies, withCookies } from 'react-cookie'
import IconButton from '../../../atoms/button/IconButton'
import { ReactComponent as CartIcon } from '../../../../../icons/sport2000-icons/cart.svg'
import { ReactComponent as CloseIcon } from '../../../../../icons/sport2000-icons/close.svg'
import { ReactComponent as LocationIcon } from '../../../../../icons/sport2000-icons/location.svg'
import { ReactComponent as MenuIcon } from '../../../../../icons/sport2000-icons/menu.svg'
import { ReactComponent as SearchIcon } from '../../../../../icons/sport2000-icons/search.svg'
import { ReactComponent as UserIcon } from '../../../../../icons/sport2000-icons/user.svg'
import Badge from '../../../atoms/badge'

const BottomNavigation = ({
    intl,
    cartItemsCount,
    toggleMenu,
    handleClickOnNavigationItem,
    currentActiveItem,
    cookies,
    node
}) => {
    const formatData = cookies?.cookies?.store ? JSON.parse(cookies.cookies.store) : null

    const checkStoreData = () => {
        if (formatData !== null) {
            return null
        }

        return '/ueber-uns/standorte'
    }

    const navigationItems = [
        {
            id: 'locations',
            icon: <LocationIcon width={24} height={24} />,
            route: checkStoreData(),
        },
        {
            id: 'search',
            icon: <SearchIcon width={24} height={24} />,
        },
        {
            id: 'menu',
            icon: <MenuIcon width={24} height={24} />,
        },
        {
            id: 'account',
            icon: <UserIcon width={24} height={24} />,
            route: 'Frontastic.Frontend.Master.Account.index',
        },
        {
            id: 'cart',
            icon: <CartIcon width={24} height={24} />,
            route: 'Frontastic.Frontend.Master.Checkout.cart',
            notificationCount: cartItemsCount,
        },
    ]

    return (
        <div className={'header-mobile--bottom-bar'}>
            {navigationItems.map((item) => (
                <IconButton
                    key={item.id}
                    className={classnames('relative h-full px-0', {
                        'is--active': currentActiveItem === item.id,
                    })}
                    type={'button'}
                    ariaLabel={intl.formatMessage({ id: `mobileNav.${item.id}` })}
                    icon={(!item.route && currentActiveItem === item.id) ||
                    (node && node.nodeType === 'search' && item.id === 'search')
                        ? <CloseIcon width={24} height={24} />
                        : item.icon}
                    onClick={() => {
                        handleClickOnNavigationItem(item.id, item.route)
                        toggleMenu(item.id)
                    }}
                >
                    <FormattedMessage id={`mobileNav.${item.id}`} />
                    {item.id === 'cart' && cartItemsCount > 0
                    && <Badge className={'absolute pointer-events-none'} count={cartItemsCount} />}
                </IconButton>
            ))}
        </div>
    )
}

BottomNavigation.propTypes = {
    intl: PropTypes.object,
    cartItemsCount: PropTypes.number,
    toggleMenu: PropTypes.func,
    handleClickOnNavigationItem: PropTypes.func,
    currentActiveItem: PropTypes.string,
    cookies: PropTypes.instanceOf(Cookies).isRequired,
    node: PropTypes.object.isRequired
}

export default withCookies(injectIntl(BottomNavigation))
