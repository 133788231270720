import { useCookies } from 'react-cookie'

const useStoreCookie = () => {
    const [cookie, setCookie] = useCookies(['store'])

    const setCookieStore = (store) => {
        setCookie('store', store, {
            path: '/',
        })
    }

    const updateCookieStore = (store) => {
        if (cookie.store?.id === store.objectID) {
            return
        }

        const formatedStore = {
            id: store.objectID,
            name: store.name,
            url: store.url,
            street: store.street,
            streetNumber: store.street_number,
            city: store.city,
            postCode: store.post_code,
            channelId: store.channel_id,
            isClickCollect: store.is_click_collect,
            gln: store.gln,
        }

        setCookieStore(formatedStore)
    }

    return {
        cookieStore: cookie.store || null,
        updateCookieStore,
    }
}

export default useStoreCookie
