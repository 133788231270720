import app from '@frontastic/catwalk/src/js/app/app'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import Select from 'react-select'
import PriceHelper from '../../../../services/price'
import { ReactComponent as LogoSports2000 } from '../../../../../icons/sport2000-icons/logos/Sport_2000_logo.svg'
import OldTagManager from '../../../../domain/OldTagManager'
import TagManager from '../../../../domain/TagManager'
import GtmService from '../../../../services/gtm'
import OldGtmService from '../../../../services/oldGtm'
import IconButton from '../../../atoms/button/IconButton'
import { DeleteIcon } from '../../../atoms/icon'
import FrontasticImage from '../../../atoms/frontasticImage'
import Price from '../../../atoms/price'
import Sport2000Logo from '../../../atoms/sport2000Logo'

const Product = ({
    intl,
    product,
}) => {
    const {
        lineItemId, name, price,
    } = product

    const isDesktop = useDeviceType() === 'desktop'
    const image = product.variant.images[0]
    const color = product.variant.attributes.att_prod_color_name_sport2000
    const link = `${product.variant.projectSpecificData.productUrl}?sku=${product.variant.sku}`

    const { runningCategoryId } = app.getApi().router.context.projectConfiguration
    const { absoluteCategoryId } = app.getApi().router.context.projectConfiguration
    const productCategoryIds = product?.variant?.projectSpecificData?.categoryIds || []

    const defaultIndexRunningCategory = productCategoryIds.findIndex((category) => category === runningCategoryId) > -1
    const defaultIndexAbsoluteCategory = productCategoryIds.findIndex((category) => category === absoluteCategoryId) > -1

    const themeLogo = () => {
        if (defaultIndexRunningCategory) {
            return (
                <>
                    <Sport2000Logo
                        name={'RunningExperts_1line'}
                        alt={'Running Expert'}
                        width={isDesktop ? '200px' : '175px'}
                        height={'32px'}
                    />
                </>
            )
        }

        if (defaultIndexAbsoluteCategory) {
            return (
                <>
                    <Sport2000Logo
                        name={'AbsoluteTeamsport_1line'}
                        alt={'Absolute Teamsport'}
                        width={isDesktop ? '200px' : '175px'}
                        height={'32px'}
                    />
                </>
            )
        }

        return (
            <>
                <LogoSports2000 width={isDesktop ? 130 : 112} height={30} />
            </>
        )
    }

    const removeLabelSize = () => {
        const size = product.variant.attributes.attr_size

        return size
    }

    const discountedPrice = () => (
        product.discountedPrice
        + product.count
        * product.discounts.reduce((a, b) => a + b.discountedAmount, 0)
    )

    const handleChange = (value) => {
        app.getLoader('cart').updateLineItem({
            lineItemId,
            count: Number(value.value),
        })
    }

    const quantityOptions = Array.from(Array(product.variant.projectSpecificData.stock).keys()).map((i) => ({
        value: i + 1, label: i + 1,
    }))

    const handleRemoveLineItem = () => {
        const gtmService = new GtmService(null, product)
        const productGTM = gtmService.createProductGTM('Cart', { quantity: product.count || 1 })
        TagManager.removeProduct(productGTM)

        // for universal analytics ( will be removed )
        const oldGtmService = new OldGtmService(null, product)
        const oldProductGTM = oldGtmService.createProductWithQuantityGTM()
        OldTagManager.removeProduct(oldProductGTM)

        return app.getLoader('cart').removeLineItem({ lineItemId })
    }

    return (
        <div className={'cart-page--item'}>
            <div className={'cart-page--item-image'}>
                {image && (
                    <FrontasticImage
                        alt={name}
                        title={name}
                        src={image}
                        height={200}
                        width={200}
                    />
                )}
            </div>

            <div className={'cart-page--item-info'}>
                <div className={'cart-page-logo-theme'}>
                    {product.projectSpecificData.store.length === 0 && (
                        themeLogo()
                    )}
                </div>
                <h5>
                    <a
                        onClick={() => {
                            app.getRouter().history.replace(link)
                        }}
                        title={name}
                    >
                        {name}
                    </a>
                </h5>

                {color && (
                    <p className={'item-color capitalize'}>
                        <FormattedMessage id={'cart.color'} />
                        {': '}
                        {color}
                    </p>
                )}

                {removeLabelSize() && (
                    <p className={'item-color capitalize'}>
                        <FormattedMessage id={'cart.size'} />
                        {': '}
                        {removeLabelSize()}
                    </p>
                )}

                <p className={'item-delivery-time'}>
                    <b>
                        <FormattedMessage id={'cart.available'} />
                    </b>
                    {' - '}
                    <FormattedMessage id={'cart.timeDelivery'} />
                </p>

                <p className={classnames('item-price-mobile md:hidden', { 'has--discount': discountedPrice() })}>
                    {discountedPrice() ? (
                        <Price className={'mr-2'} value={discountedPrice()} />
                    )
                        : (
                            <Price className={'original-price text-xl'} value={price} />
                        )}
                </p>

                <div className={'cart-page--item-quantity'}>
                    <Select
                        aria-label={intl.formatMessage({ id: 'product.quantityLabel' })}
                        defaultValue={{ value: product.count, label: product.count }}
                        options={quantityOptions}
                        className={'select-container'}
                        classNamePrefix={'select'}
                        onChange={(value) => handleChange(value)}
                    />
                </div>

                <div className={'cart-page--item-action'}>
                    <IconButton
                        className={'btn btn-text icon--left'}
                        type={'button'}
                        ariaLabel={intl.formatMessage({ id: 'cart.remove' })}
                        onClick={handleRemoveLineItem}
                        icon={<DeleteIcon width={20} height={20} />}
                    >
                        <FormattedMessage id={'cart.remove'} />
                    </IconButton>
                </div>
            </div>
            <div className={'cart-page--price'}>
                {PriceHelper.isDiscountOverThreshold(product.discountedPrice, price) ? (
                    <Price className={'discount-price text-xl'} value={discountedPrice() || price} />
                )
                    : (
                        <Price className={'original-price text-xl'} value={product.discountedPrice || price} />
                    )}
            </div>
        </div>
    )
}

Product.propTypes = {
    intl: PropTypes.object.isRequired,
    product: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default injectIntl(Product)
