import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import Button from '../../atoms/button/Button'

const SearchLocalStorage = ({
    setCurrentValue, node,
}) => {
    const getValueButton = (searchTerm) => {
        if (node && node.nodeType && node.nodeType === 'search') {
            window.location.href = `/search/?query=${searchTerm}`
            return
        }

        setCurrentValue(searchTerm)
    }

    const enrichLocalStorage = () => {
        if (typeof localStorage === "undefined") {
            return null
        }
        const data = JSON.parse(localStorage.getItem('search'))

        let result = []

        if (data && data.length) {
            result = data.filter((element, index) => index >= data.length - 5)
        }

        if (!result || !result.length) {
            return null
        }

        return (
            result.map((searchTerm) => (
                <div className={'search--layout--content-wrap'} key={searchTerm}>
                    {searchTerm && (
                        <Button
                            className={'search--local-content--item search--layout--content-wrap-item'}
                            type={'button'}
                            value={searchTerm}
                            onClick={() => {
                                getValueButton(searchTerm)
                            }}
                        >
                            <span>{searchTerm}</span>
                        </Button>
                    )}
                </div>
            ))
        )
    }

    return (
        <div className={'search--local-layout search--layout'}>
            <h4 className={'search-hits--headline'}>
                <FormattedMessage id={'search.searchHits.localStorage'} />
            </h4>
            <div className={'search--local-layout--content search--layout--content'}>
                <>
                    {enrichLocalStorage()}
                </>
            </div>
        </div>

    )
}

SearchLocalStorage.propTypes = {
    setCurrentValue: PropTypes.func,
    node: PropTypes.any,
}

export default SearchLocalStorage
