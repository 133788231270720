import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Helmet } from 'react-helmet'

import ComponentInjector from '@frontastic/catwalk/src/js/app/injector'
import getTranslation from '@frontastic/catwalk/src/js/getTranslation'
import {withRouter} from 'next/router';
import {getBaseUrl} from '@frontastic/catwalk/js/helper/getBaseUrl';

class Canonical extends Component {
    render () {
        const canonicalLink = this.generateCanonicalLink()
        if (canonicalLink === '') {
            return null
        }

        return (<Helmet>
            <link rel="canonical" href={canonicalLink} />
        </Helmet>)
    }

    generateCanonicalLink = () => {
        if (!this.props.node.configuration.canonical) {
            return getBaseUrl(this.props.router)
        }

        return getTranslation(
            this.props.node.configuration.canonical,
            this.props.context.locale,
            this.props.context.project.defaultLanguage
        ).text
    }
}

Canonical.propTypes = {
    node: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
}

Canonical.defaultProps = {}

export default withRouter(ComponentInjector.return('Node.Meta.Canonical', Canonical))
