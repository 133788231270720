import app from '@frontastic/catwalk/src/js/app/app'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import PriceHelper from 'anwr_sport2000/src/js/services/price'
import { LoadingIcon } from '../../../../atoms/icon'
import FrontasticImage from '../../../../atoms/frontasticImage'
import Price from '../../../../atoms/price'

const CartItem = ({
    image, name, color, size, count, price, discountedPrice, url,
}) => (
    <div className={'mini-cart--item'}>
        <div className={'mini-cart--item-image'}>
            <FrontasticImage
                src={image}
                alt={name}
                width={150}
                height={150}
            />
        </div>
        <div className={'mini-cart--item-info'}>
            <h5>
                <a
                    title={name}
                    onClick={(e) => {
                        e.stopPropagation()
                        app.getRouter().history.replace(url)
                    }}
                >
                    {name}
                </a>
            </h5>

            {color && (
                <div className={'capitalize'}>
                    <FormattedMessage id={'miniCart.color'} />
                    {' '}
                    {color}
                </div>
            )}

            {size && (
                <div className={'capitalize'}>
                    <FormattedMessage id={'miniCart.size'} />
                    {' '}
                    {size}
                </div>
            )}

            <div className={'inline-flex'}>
                <FormattedMessage id={'miniCart.count'} />
                {' '}
                {!count ? <LoadingIcon width={16} height={16} className={'mini-cart--loading'} /> : count}
            </div>

            <div className={'is--price font-bold'}>
                {PriceHelper.isDiscountOverThreshold(discountedPrice, price)
                    ? (
                        <Price value={discountedPrice || price} className={'is--discount-price'} />
                    )
                    : (
                        <Price value={discountedPrice || price} />
                    )}
            </div>
        </div>
    </div>
)

CartItem.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.string,
    count: PropTypes.number,
    price: PropTypes.number,
    discountedPrice: PropTypes.number,
    url: PropTypes.string.isRequired,
}

export default injectIntl(CartItem)
