import PropTypes from 'prop-types'
import React, { useState, useMemo } from 'react'
import CloudinaryLoader from './loader'
import NextImage from 'next/image'

const BASE_WIDTH = 400

const ProductImage = ({
    url,
    width = BASE_WIDTH,
    height,
    alt,
    title,
    quality = 80,
    props = {},
}) => {
    if (!url) {
        return null
    }

    const blurDataUrl = useMemo(() => CloudinaryLoader({ src: url, width: 10, quality: 10 }), [url])

    return (
        <NextImage
            loader={CloudinaryLoader}
            src={url}
            alt={alt}
            title={title}
            placeholder="blur"
            width={width}
            height={height || 400}
            blurDataURL={blurDataUrl}
            quality={quality}
            {...props}
        />
    )
}

ProductImage.propTypes = {
    url: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
    alt: PropTypes.string,
    title: PropTypes.string,
    quality: PropTypes.number,
    loading: PropTypes.oneOf(['lazy', 'eager']),
}

export default ProductImage
