import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import classnames from 'classnames'
import SearchBox from './SearchBox'
import SearchSuggestHits from './SearchSuggestHits'
import SearchBrandHits from './SearchBrandHits'
import SearchSuggestLayout from './SearchSuggestLayout'
import SearchLocalStorage from './SearchLocalStorage'
import SearchLinkLayout from './SearchLinkLayout'
import SearchCategory from './SearchCategory'
import SearchHits from './SearchHits'
import SearchBlogHits from './SearchBlogHits'
import ClientFactory from '../../../services/algolia/ClientFactory'
import EmptyResult from './EmptyResult'
import TagManager from '../../../domain/TagManager'
import SearchStoreHits from './SearchStoreHits'

const Search = ({
    mobileSetCurrentActiveItem,
    mobileToggleMenu,
    mobileSearchMenuOpen,
    placeholderSearch,
    context,
    searchFAQ,
    node,
    searchHitsOpen,
    setSearchHitsOpen,
    categories,
    themeName,
    nbOfCharactersToTriggerSearch
}) => {
    const [valueText, setValueText] = useState('')
    const [triggerClickInput, setTriggerClickInput] = useState(false)
    const [isSearchCategoryEmpty, setIsSearchCategoryEmpty] = useState(false)
    const [searchBrandUrl, setSearchBrandUrl] = useState([])
    const [selectedCategoryOption, setSelectedCategoryOption] = useState(null)
    const [filteredCategory, setFilteredCategory] = useState(null)
    const [isShowCategoryEmptyMsg, setIsShowCategoryEmptyMsg] = useState(false)
    const [isSearchPage, setIsSearchPage] = useState(false)
    const [specialCategoryUrl, setSpecialCategoryUrl] = useState('')
    const [searchSuggestionResults, setSearchSuggestionResults] = useState({})
    const [isSearching, setIsSearching] = useState(false)
    const [currentValue, setCurrentValue] = useState('')

    const isAllSearchEmpty = useMemo(() => {
        const {
            category: categorySearchResult,
            suggestion: suggestionSearchResult,
            brand: brandSearchResult,
            store: storeSearchResult,
            product: productSearchResult,
            blog: blogSearchResult,
        } = searchSuggestionResults || {}

        return searchSuggestionResults
            && !!categorySearchResult?.hits?.length
            && !!suggestionSearchResult?.hits?.length
            && !!brandSearchResult?.hits?.length
            && !!storeSearchResult?.hits?.length
            && !!productSearchResult?.hits?.length
            && !!blogSearchResult?.hits?.length
    }, [searchSuggestionResults])
    const config = ClientFactory.getConfig(context)
    let currentRequest

    if (!config || !config.appId || !config.searchApiKey || !config.indexName) {
        return null
    }

    const handleRedirectWithQuery = (redirectUrl) => {
        const currentFullPathUrl = `https://${window.location.hostname}`

        if (currentFullPathUrl !== redirectUrl) {
            window.location.href = redirectUrl
        }
    }

    const handleSearchSuggestion = () => {
        if (!valueText || valueText.length < nbOfCharactersToTriggerSearch) {
            return
        }

        try {
            setIsSearching(true)
            ClientFactory.getSearchSuggestions(context, valueText, filteredCategory).then((response) => {
                const redirectUrl = response.product?.renderingContent?.redirect?.url

                if (redirectUrl) {
                    handleRedirectWithQuery(redirectUrl)
                    setSearchSuggestionResults({})
                    setIsSearching(false)
                    return
                }

                setIsSearching(false)
                setSearchSuggestionResults(response)
            })
        } catch (error) {
            console.warn({ error })
        }
    }

    useEffect(() => {
        if (searchHitsOpen) {
            TagManager.hitViewdImpressions()
        }

        if (node && node.nodeType && node.nodeType === 'search') {
            setIsSearchPage(true)
        }
    }, [])

    useEffect(() => {
        if (searchHitsOpen) {
            document.body.style.top = `-${document.documentElement.scrollTop}px`
            document.body.classList.add('fixed')

            return
        }

        document.body.style.removeProperty('top')
        document.body.classList.remove('fixed')
    }, [searchHitsOpen])

    useEffect(() => {
        if (!currentValue || !valueText || valueText.length < nbOfCharactersToTriggerSearch) {
            setTriggerClickInput(true)
            return
        }

        setTriggerClickInput(false)
    }, [currentValue, valueText, nbOfCharactersToTriggerSearch])

    useEffect(() => {
        handleSearchSuggestion()
    }, [valueText, filteredCategory])

    return (
        <div className={'h-full flex flex-col'}>
            <SearchBox
                setSearchHitsOpen={setSearchHitsOpen}
                mobileSetCurrentActiveItem={mobileSetCurrentActiveItem}
                mobileToggleMenu={mobileToggleMenu}
                mobileSearchMenuOpen={mobileSearchMenuOpen}
                placeholderSearch={placeholderSearch}
                valueText={valueText}
                setValueText={setValueText}
                setTriggerClickInput={setTriggerClickInput}
                searchBrandUrl={searchBrandUrl}
                searchHitsOpen={searchHitsOpen}
                isSearchPage={isSearchPage}
                categories={categories}
                selectedCategoryOption={selectedCategoryOption}
                setSelectedCategoryOption={setSelectedCategoryOption}
                filteredCategory={filteredCategory}
                setFilteredCategory={setFilteredCategory}
                isShowCategoryEmptyMsg={isShowCategoryEmptyMsg}
                setIsShowCategoryEmptyMsg={setIsShowCategoryEmptyMsg}
                node={node}
                themeName={themeName}
                specialCategoryUrl={specialCategoryUrl}
                searching={isSearching}
                currentValue={currentValue}
                setCurrentValue={setCurrentValue}
            />

            {searchHitsOpen && (
                <div
                    className={classnames('search search-hits', {
                        hidden: !searchHitsOpen,
                    })}
                >
                    {triggerClickInput ? (
                        <div className={'search--hits-first-layout'}>
                            <SearchLocalStorage
                                setCurrentValue={setCurrentValue}
                                node={node}
                            />
                            <SearchSuggestLayout
                                context={context}
                                setCurrentValue={setCurrentValue}
                                node={node}
                                isSearchPage={isSearchPage}
                            />
                            <SearchLinkLayout searchFAQ={searchFAQ} />
                        </div>
                    ) : (
                        <>
                            <EmptyResult
                                isAllSearchEmpty={isAllSearchEmpty}
                                isSearchCategoryEmpty={isSearchCategoryEmpty}
                                selectedCategoryOption={selectedCategoryOption}
                                isShowCategoryEmptyMsg={isShowCategoryEmptyMsg}
                            />
                            <div className={'search-hits-wrapper'}>
                                <div className={'search-hits-wrapper--left'}>
                                    <SearchCategory
                                        searchResult={searchSuggestionResults.category}
                                        setIsSearchCategoryEmpty={setIsSearchCategoryEmpty}
                                        setSpecialCategoryUrl={setSpecialCategoryUrl}
                                        valueText={valueText}
                                        selectedCategoryOption={selectedCategoryOption}
                                        searchBrandUrl={searchBrandUrl}
                                    />
                                    <SearchSuggestHits
                                        searchResult={searchSuggestionResults.suggestion}
                                        searchHitsOpen={searchHitsOpen}
                                        setCurrentValue={setCurrentValue}
                                        triggerClickInput={triggerClickInput}
                                    />
                                    <SearchBrandHits
                                        searchResult={searchSuggestionResults.brand}
                                        searchHitsOpen={searchHitsOpen}
                                        valueText={valueText}
                                        onUpdateBrandUrl={(url) => {
                                            if (url !== searchBrandUrl) {
                                                setSearchBrandUrl(url)
                                            }
                                        }}
                                    />
                                    <SearchStoreHits
                                        searchResult={searchSuggestionResults.store}
                                        searchHitsOpen={searchHitsOpen}
                                        triggerClickInput={triggerClickInput}
                                        selectedCategoryOption={selectedCategoryOption}
                                    />
                                </div>

                                <div className={'search-hits-wrapper--right'}>
                                    <SearchHits
                                        searchResult={searchSuggestionResults.product}
                                        searchHitsOpen={searchHitsOpen}
                                        value={valueText}
                                        selectedCategoryOption={selectedCategoryOption}
                                        filteredCategory={filteredCategory}
                                        setFilteredCategory={setFilteredCategory}
                                        setIsShowCategoryEmptyMsg={setIsShowCategoryEmptyMsg}
                                    />
                                    <SearchBlogHits
                                        searchResult={searchSuggestionResults.blog}
                                        searchHitsOpen={searchHitsOpen}
                                        selectedCategoryOption={selectedCategoryOption}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    )
}

Search.propTypes = {
    mobileSetCurrentActiveItem: PropTypes.any,
    mobileToggleMenu: PropTypes.any,
    mobileSearchMenuOpen: PropTypes.any,
    placeholderSearch: PropTypes.any,
    context: PropTypes.any,
    node: PropTypes.any,
    searchFAQ: PropTypes.arrayOf(PropTypes.any).isRequired,
    searchHitsOpen: PropTypes.any,
    setSearchHitsOpen: PropTypes.any,
    categories: PropTypes.arrayOf(PropTypes.any).isRequired,
    themeName: PropTypes.string,
    nbOfCharactersToTriggerSearch: PropTypes.number,
}

export default Search
