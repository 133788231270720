import React from 'react';
import { useSelector } from 'react-redux';

type Sport2000LogoType =
  | 'TeamsportExperts'
  | 'OutdoorExperts_1line_neg'
  | 'TeamsportExperts_neg'
  | 'TeamsportExperts_1line'
  | 'TeamsportExperts_1line_neg'
  | 'AbsoluteTeamsport_1line_neg'
  | 'AbsoluteTeamsport_1line'
  | 'AbsoluteTeamsport_2line_neg'
  | 'AbsoluteTeamsport_2line'
  | 'SkiExperts'
  | 'SkiExperts_1line_neg'
  | 'SkiExperts_1line'
  | 'SkiExperts_neg'
  | 'RunningExperts_1line'
  | 'RunningExperts'
  | 'RunningExperts_neg'
  | 'RunningExperts_1line_neg'
  | 'AbsoluteRun_1line'
  | 'AbsoluteRun_2line_neg'
  | 'AbsoluteRun_1line_neg'
  | 'AbsoluteRun_2line'
  | 'OutdoorExperts_1line'
  | 'OutdoorExperts'
  | 'OutdoorExperts_neg'
  | 'Sport2000';

type Sport2000LogoProps = {
  name: Sport2000LogoType;
  alt?: string;
  width?: string;
  height?: string;
  className?: string;
  logoDefault?: string;
};

type LogoItem = {
  name: string;
  url: string;
};

const Sport2000Logo: React.FC<Sport2000LogoProps> = ({
  name,
  alt,
  logoDefault,
  width = 'auto',
  height = 'auto',
  className = '',
}) => {
  const context = useSelector((state: any) => state.app.context);

  if (!name) {
    return null;
  }

  const sport2000LogoUrls: LogoItem[] = context?.projectConfiguration?.sportLogoUrls || [];
  const logoItem = sport2000LogoUrls.find((item: LogoItem) => item.name.toLowerCase() === name.toLowerCase());

  if (logoItem) {
    return (
      <img style={{ width, height }} src={logoItem.url} alt={alt || `logo ${logoItem.name}`} className={className} />
    );
  }

  if (logoDefault) {
    return <img style={{ width, height }} src={logoDefault} alt={alt || `logo ${name}`} className={className} />;
  }

  return null;
};

export default Sport2000Logo;
