import fetch from '@frontastic/bridge/js/fetch'

class CategoryService {
    isCategoryActive(sidebarCategory, currentCategory, selectedCategorySidebar = '') {
        if (selectedCategorySidebar === sidebarCategory.key) {
            return true
        }

        if (sidebarCategory.key === currentCategory?.projectSpecificData?.categoryKey) {
            return true
        }

        if (currentCategory?.projectSpecificData?.ancestors?.includes(sidebarCategory.id)) {
            return true
        }

        if (sidebarCategory?.children?.length > 0) {
            for (const child of sidebarCategory?.children) {
                if (this.isCategoryActive(child, currentCategory)) {
                    return true
                }
            }
        }

        return sidebarCategory.active
    }

    async getSubCategory(categoryId) {
        const res = await fetch(`/api/subcategories/list/${categoryId}/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return res.json()
    }

    async getUrls(names) {
        const res = await fetch('/api/categories/url/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                names,
            }),
        })
        return res.json()
    }

    async getUrlsByPath(paths) {
        const res = await fetch('/api/categories/url/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                paths,
            }),
        })
        return res.json()
    }
}

export default new CategoryService()
