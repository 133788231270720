import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Helmet } from 'react-helmet'

import ComponentInjector from '@frontastic/catwalk/src/js/app/injector'
import { ConfigurationSchema } from 'frontastic-common'

const PAGE_ALLOW_ROBOT_INDEX_FIELD = 'pageRobotsIndexFollow'
const PAGE_ALLOW_ROBOT_INDEX_DEFAULT = 'landingpage,category,product,content'

class Robots extends Component {
    render () {
        if (this.isAllowIndex()) {
            return (<Helmet>
                <meta name="robots" content="index,follow" />
            </Helmet>)
        }

        return (<Helmet>
            <meta name="robots" content="noindex,nofollow" />
        </Helmet>)
    }

    isAllowIndex = () => {
        const projectSchema = new ConfigurationSchema(
            this.props.context.projectConfigurationSchema,
            this.props.context.projectConfiguration)

        let pageAllowRobotIndex = projectSchema.has(PAGE_ALLOW_ROBOT_INDEX_FIELD) ? projectSchema.get(PAGE_ALLOW_ROBOT_INDEX_FIELD) : PAGE_ALLOW_ROBOT_INDEX_DEFAULT
        pageAllowRobotIndex = pageAllowRobotIndex.split(',')

        if (pageAllowRobotIndex.indexOf(this.props.node.nodeType) !== -1) {
            return true
        }

        return false
    }
}

Robots.propTypes = {
    node: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
}

Robots.defaultProps = {}

export default ComponentInjector.return('Node.Meta.Robots', Robots)
