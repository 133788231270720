import React, { Component } from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Base from './base';
import Canonical from './canonical';
import OpenGraph from './openGraph';
import Robots from './robots';

class MetaData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.getTitle(),
    };
  }

  componentDidMount() {
    if (!!this.props.node) {
      this.setState({
        title: this.getTitle(),
      });
    }
  }

  getNodeTitle() {
    if (this.props.node.configuration.seoTitle) {
      return this.props.node.configuration.seoTitle;
    }

    if (this.props.node.name) {
      return this.props.node.name;
    }

    return '';
  }

  getTitle() {
    return (
      (this.props.context.projectConfiguration.frontasticPageTitlePrefix ?? '') +
      this.getNodeTitle() +
      (this.props.context.projectConfiguration.frontasticPageTitleSuffix ?? '')
    );
  }

  render() {
    return (
      <>
        <Head>
          <title>{this.state.title}</title>
        </Head>
        <Base node={this.props.node} context={this.props.context} />
        <Canonical node={this.props.node} context={this.props.context} />
        <OpenGraph node={this.props.node} context={this.props.context} />
        <Robots node={this.props.node} context={this.props.context} />
      </>
    );
  }
}

MetaData.propTypes = {
  node: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
  page: PropTypes.object,
  data: PropTypes.object,
};

MetaData.defaultProps = {};

export default connect((globalState, props) => ({
  ...props,
  context: globalState.app.context,
}))(MetaData);
