import React, { MouseEventHandler } from 'react'
import classnames from 'classnames'
import Link from '@frontastic/catwalk/src/js/app/link'

export type Props = {
    type: 'button' | 'submit' | 'reset' | undefined
    title: string
    href: string
    className?: String
    children?: React.ReactNode
    onClick?: MouseEventHandler
    darkTheme?: boolean
    fullWidth?: boolean
    icon: React.ReactNode
    style?: React.CSSProperties
}

const IconLinkButton: React.FC<Props> = ({
    title,
    href,
    className,
    children,
    onClick = () => {
    },
    darkTheme = false,
    fullWidth = false,
    icon,
    style,
}: Props) => (
    <Link
        // @ts-ignore
        className={classnames(className, {
            'dark-mode': darkTheme,
            'full-width': fullWidth,
        })}
        title={title}
        onClick={onClick}
        style={style}
        path={href}
    >
        {icon && icon}
        {children && children}
    </Link>
)

export default IconLinkButton
