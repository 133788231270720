import React from 'react'
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl'
import { ReactComponent as InfoIcon } from '../../../../icons/sport2000-icons/info.svg'

export type Props = {
    isAllSearchEmpty: boolean
    isSearchCategoryEmpty: boolean
    selectedCategoryOption: any
    isShowCategoryEmptyMsg: boolean
} & WrappedComponentProps

const SearchAllHits: React.FC<Props> = ({
    isAllSearchEmpty, isSearchCategoryEmpty, selectedCategoryOption, isShowCategoryEmptyMsg,
}: Props) => {
    const selectedCategoryName = selectedCategoryOption?.name || ''

    const renderEmptyMessage = () => {
        if (isAllSearchEmpty) {
            return (
                <FormattedMessage id={'search.searchHits.empty'} />
            )
        }

        if (selectedCategoryName && isSearchCategoryEmpty) {
            return (
                <div className={'flex search--empty-product'}>
                    <div className={'search--empty-icon my-auto'}>
                        <InfoIcon width={20} height={20} />
                    </div>
                    <p className={'ml-3'}>
                        <FormattedMessage id={'search.searchHits.emptyCategory'} />
                        {selectedCategoryName && (
                            <span className={'font-bold'}>
                                {`"${selectedCategoryName}"`}
                            </span>
                        )}
                        <FormattedMessage id={'search.searchHits.emptyCategoryLast'} />
                    </p>
                </div>
            )
        }

        if (isSearchCategoryEmpty) {
            return (
                <div className={'search--empty-all-product'}>
                    <FormattedMessage id={'search.searchHits.empty'} />
                </div>
            )
        }

        return null
    }

    return (
        <div className={'search--empty'}>
            {renderEmptyMessage()}
        </div>
    )
}

export default injectIntl(SearchAllHits)
