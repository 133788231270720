import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from 'react-intl'
import app from '@frontastic/catwalk/src/js/app/app'
import classnames from 'classnames'
import FullPageWidthWrapper from '../../../molecules/Layout/FullPageWidthWrapper'
import Navigation from './Navigation'
import IconNavigation from './IconNavigation'
import {ReactComponent as LocationIcon} from '../../../../../icons/sport2000-icons/location.svg'
import {ReactComponent as ArrowRightIcon} from '../../../../../icons/sport2000-icons/arrow-right.svg'
import Search from '../../SearchAlgolia/Search'
import useStoreCookie from '../../../../services/hook/useStoreCookie'
import {DomElement} from '@frontastic/bridge/js/types';

const Desktop = ({
  category,
  logoImageWithText,
  logoImageOnly,
  currentTopCategory,
  cartItemsCount,
  goToCartPage,
  goToProfilePage,
  themeName,
  goToCheckoutPage,
  isOpenMiniCart,
  cart,
  refMiniCart,
  closeMiniCart,
  currentNavigation,
  onFlyoutToggle,
  placeholderSearch,
  context,
  searchFAQ,
  categorySidebar,
  searchHitsOpen,
  setSearchHitsOpen,
  goToWishlistPage,
  wishListLineItemsCount,
  node,
  themeSearchPage,
  absoluteTeamsportBrand,
  runningExpertBrand,
  nbOfCharactersToTriggerSearch,
}) => {
  const currentTree = category[currentTopCategory]?.customTree || category[currentTopCategory]?.tree || category[currentTopCategory]?.children || []

  const {cookieStore} = useStoreCookie(context)

  const [storePage] = useState(app.getApi().router.context.projectConfiguration.storePage)

  let storeFinderUrl = app.getApi().router.path(`node_${storePage}`)
  switch (themeName) {
    case 'theme-absolute-teamsport':
      storeFinderUrl += '?logo=absolute-teamsport'
      break
    case 'theme-running-expert':
      storeFinderUrl += '?logo=running-experts'
      break
    case 'theme-absolute-run':
      storeFinderUrl += '?logo=absolute-run'
      break
    default:
      break
  }

  const defaultIndexRunningCategory = node && node.nodeType && node.nodeType === 'search' && themeSearchPage === 'Running'
  const defaultIndexAbsoluteCategory = node && node.nodeType && node.nodeType === 'search' && themeSearchPage === 'Fussball'

  // Add theme to change header logo and add border in dropdown category search suggestion
  useEffect(() => {
    document.body.classList.remove('theme--green')
    document.body.classList.remove('theme--blue')

    if (defaultIndexAbsoluteCategory) {
      document.body.classList.add('theme--green')
    }
    if (defaultIndexRunningCategory) {
      document.body.classList.add('theme--blue')
    }
  }, [themeSearchPage, node])

  let categoryBrand

  switch (themeName) {
    case 'theme-absolute-teamsport':
      categoryBrand = absoluteTeamsportBrand
      break
    case 'theme-running-expert':
      categoryBrand = runningExpertBrand
      break
    default:
      break
  }

  return (
    <FullPageWidthWrapper customClasses={'lg:block'}>
      <nav className="bg-white">
        <div className={'top-menu-wrapper hidden lg:block'}>
          <div className={classnames('o-wrapper h-full', {
            'wrapper-sport': themeName === '',
          })}
          >
            <div className={'flex items-center justify-center h-full relative mt-15px'}>
              <Search
                placeholderSearch={placeholderSearch}
                context={context}
                searchFAQ={searchFAQ}
                searchHitsOpen={searchHitsOpen}
                setSearchHitsOpen={setSearchHitsOpen}
                node={node}
                categories={category}
                themeName={themeName}
                nbOfCharactersToTriggerSearch={nbOfCharactersToTriggerSearch}
              />

              <div className={'flex h-full absolute right-0 max-h-41px'}>
                <div className={'store-details-header pr-5'}>
                  <div className={'store-details-header-icon'}>
                    <LocationIcon width={20} height={20}/>
                  </div>
                  <div className={'store-details-header-content lg:overflow-hidden'}>
                    <div className={'store-detail-header-content-headline'}>
                      {cookieStore ? (
                        <FormattedMessage id={'storeDetail.headerStoreDataHeadline'}/>
                      ) : (
                        <FormattedMessage id={'storeDetail.headerStoreHeadline'}/>
                      )}
                    </div>
                    <div className={'store-details-header-name'}>
                      {cookieStore ? (
                        <a
                          href={cookieStore.url}
                          target={'_blank'}
                          rel={'noreferrer'}
                        >
                          <u>{cookieStore.name}</u>
                        </a>
                      ) : (
                        <a href={storeFinderUrl} className={'underline  '}>
                          <FormattedMessage id={'storeDetail.headerStoreLink'}/>
                        </a>
                      )}
                    </div>
                  </div>
                  <div className={'store-details-header-flyout'}>
                    <div className={'store-details-header-flyout-wrap'}>
                      <div className={'font-bold mb-2'}>
                        <FormattedMessage id={'storeDetail.headerMyStore'}/>
                      </div>
                      {cookieStore && (
                        <div className={'flex relative'}>
                          <a
                            href={cookieStore.url}
                            target={'_blank'}
                            rel={'noreferrer'}
                          >
                            <div>
                              <u>{cookieStore.name}</u>
                              <br/>
                              {cookieStore.street}
                              {' '}
                              {cookieStore.streetNumber}
                              <br/>
                              {cookieStore.postCode}
                              {' '}
                              {cookieStore.city}
                            </div>
                          </a>
                          <ArrowRightIcon
                            width={20}
                            height={20}
                            className={'store-details-header-flyout-wrap-icon'}
                          />
                        </div>
                      )}
                      <a
                        className={'btn btn-default store-details-header-flyout-wrap-content-button w-full mt-4'}
                        href={storeFinderUrl}
                      >
                        <FormattedMessage id={'storeDetail.headerButtonLink'}/>
                      </a>
                    </div>
                  </div>
                </div>
                <IconNavigation
                  cartItemsCount={cartItemsCount}
                  goToCartPage={goToCartPage}
                  goToProfilePage={goToProfilePage}
                  goToCheckoutPage={goToCheckoutPage}
                  isOpenMiniCart={isOpenMiniCart}
                  cart={cart}
                  refMiniCart={refMiniCart}
                  closeMiniCart={closeMiniCart}
                  wishListLineItemsCount={wishListLineItemsCount}
                  goToWishlistPage={goToWishlistPage}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={classnames('main-menu-wrapper o-wrapper', {
            'wrapper-sport': themeName === '',
          })}
        >
          <div className={'relative'}>
            <Navigation
              logoImageWithText={logoImageWithText}
              logoImageOnly={logoImageOnly}
              currentTree={currentTree}
              currentNavigation={currentNavigation}
              onFlyoutToggle={onFlyoutToggle}
              categorySidebar={categorySidebar}
              themeName={themeName}
              categoryBrand={categoryBrand}
            />
          </div>
        </div>
      </nav>
    </FullPageWidthWrapper>
  )
}

Desktop.propTypes = {
  category: PropTypes.arrayOf(PropTypes.any).isRequired,
  logoImageWithText: PropTypes.objectOf(PropTypes.object).isRequired,
  logoImageOnly: PropTypes.objectOf(PropTypes.object).isRequired,
  currentTopCategory: PropTypes.number.isRequired,
  cartItemsCount: PropTypes.number.isRequired,
  goToCartPage: PropTypes.func.isRequired,
  closeMiniCart: PropTypes.func.isRequired,
  goToProfilePage: PropTypes.func.isRequired,
  goToCheckoutPage: PropTypes.func.isRequired,
  cart: PropTypes.objectOf(PropTypes.any).isRequired,
  isOpenMiniCart: PropTypes.bool.isRequired,
  currentNavigation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
  refMiniCart: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.instanceOf(DomElement)}),
  ]).isRequired,
  placeholderSearch: PropTypes.string,
  onFlyoutToggle: PropTypes.func,
  searchFAQ: PropTypes.arrayOf(PropTypes.any).isRequired,
  context: PropTypes.object.isRequired,
  categorySidebar: PropTypes.any,
  searchHitsOpen: PropTypes.any,
  setSearchHitsOpen: PropTypes.any,
  themeName: PropTypes.any,
  goToWishlistPage: PropTypes.func,
  wishListLineItemsCount: PropTypes.number,
  node: PropTypes.object.isRequired,
  themeSearchPage: PropTypes.string,
  absoluteTeamsportBrand: PropTypes.any,
  runningExpertBrand: PropTypes.any,
  nbOfCharactersToTriggerSearch: PropTypes.number,
}

export default injectIntl(Desktop)
