import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import ClientFactory from '../../../services/algolia/ClientFactory'
import Button from '../../atoms/button/Button'

const SearchSuggestLayout = ({
    setCurrentValue, context, isSearchPage,
}) => {
    const [labelQuery, setLabelQuery] = useState([])

    const getValueButton = (query) => {
        if (isSearchPage) {
            window.location.href = `/search/?query=${query}`
            return
        }

        setCurrentValue(query)
    }

    useEffect(() => {
        let isMounted = true
        ClientFactory.querySearchSuggestion(context, '').then((res) => {
            if (isMounted) {
                setLabelQuery(res.results[0]?.hits)
            }
        })
        return () => {
            isMounted = false
        }
    }, [])

    return (
        <div className={'search--suggest-layout search--layout'}>
            <h4 className={'search-hits--headline'}>
                <FormattedMessage id={'search.searchHits.suggest'} />
            </h4>
            <div className={'search--suggest-layout--content search--layout--content'}>
                <>
                    {labelQuery?.map((hit) => (
                        <div className={'search--layout--content-wrap'} key={hit.query}>
                            {hit.query && (
                                <Button
                                    className={'search--suggest-layout--item search--layout--content-wrap-item'}
                                    type={'button'}
                                    value={hit.query}
                                    onClick={(e) => {
                                        getValueButton(hit.query)
                                    }}
                                >
                                    <span>{hit.query}</span>
                                </Button>
                            )}
                        </div>
                    ))}
                </>
            </div>
        </div>
    )
}

SearchSuggestLayout.propTypes = {
    setCurrentValue: PropTypes.func,
    context: PropTypes.object.isRequired,
    isSearchPage: PropTypes.bool,
}

export default SearchSuggestLayout
