export type ThemeAction = {
    type: string,
    data: ThemeActionData | null,
}

export type ThemeActionData = {
    themeName?: string
}

export const setTheme = (state: ThemeActionData): ThemeAction => ({
    type: 'ACTION_SET_THEME',
    data: state,
})
