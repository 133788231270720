import React from 'react'
import app from '@frontastic/catwalk/src/js/app/app'
import { closeMiniCartAction, openMiniCartAction, saveAddedProductAction } from '../store/actions'
import fetch from '@frontastic/bridge/js/fetch'

class CartService {
    closeMiniCart() {
        return closeMiniCartAction()
    }

    openMiniCart() {
        return openMiniCartAction()
    }

    saveAddedProduct(variant, name, count) {
        return saveAddedProductAction({
            ...variant,
            name,
            count,
        })
    }

    addToCart(variant, count, projectSpecificData) {
        app.getStore().dispatch({
            type: 'CartApi.Cart.loading',
        })
        return fetch('/api/cart/cart/add?ownErrorHandler=1', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ variant, count, projectSpecificData }),
        }).then((response) => response.json()).catch((error) => {
            throw error
        })
    }
}

export default new CartService()
