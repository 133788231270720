import app from '@frontastic/catwalk/src/js/app/app'

export const setCloseMiniCart = () => ({
    type: 'ACTION_CLOSE_MINI_CART',
})

export const setOpenMiniCart = () => ({
    type: 'ACTION_OPEN_MINI_CART',
})

export const setSaveAddedNewProduct = (product) => ({
    type: 'ACTION_SAVE_ADDED_NEW_PRODUCT',
    payload: product,
})

export const saveAddedProductAction = (product) => {
    const store = app.getStore()
    store.dispatch(setSaveAddedNewProduct(product))
}

export const closeMiniCartAction = () => {
    const store = app.getStore()
    store.dispatch(setCloseMiniCart())
}

export const openMiniCartAction = () => {
    const store = app.getStore()
    store.dispatch(setOpenMiniCart())
}
