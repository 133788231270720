const normalizeSrc = (src: string): string => (src?.[0] === '/' ? src?.slice(1) : src)

export const getImageName = (url: string): string | null => {
    const parts = url.split('/')
    const lastPart = parts[parts.length - 1]

    return lastPart.trim() !== '' ? lastPart : null
}

const DEFAULT_VERSION_PATTERN = /\/v\d+\//
const DEFAULT_IMAGE_DOMAIN = 'image.sport2000.de'
const CLOUDINARY_DOMAIN = 'res.cloudinary.com'

const appendTransformation = (src: string, params: string): string => {
    const imageName = getImageName(src)

    let customSrc = src.replace(CLOUDINARY_DOMAIN, DEFAULT_IMAGE_DOMAIN)

    customSrc = DEFAULT_VERSION_PATTERN.test(customSrc)
        ? customSrc.replace(DEFAULT_VERSION_PATTERN, (match) => `/${params}${normalizeSrc(match)}`)
        : customSrc.replace(imageName, (match) => `${params}${match}`)

    return customSrc
}

const isExistedTransformationInSrc = (src: string): boolean => ['q_auto', 'f_auto', 'c_pad'].some((transform) => src?.includes(transform))

const CloudinaryLoader = ({
    src, width, quality = 100,
}: any): string => {
    const customWidthByScreen = Math.round(+width * quality / 100)

    const generateParamsForProduct = () => [
        ...(isExistedTransformationInSrc(src as string) ? ['c_pad'] : ['f_auto', 'q_auto', 'c_pad']),
        ...[`w_${customWidthByScreen}`],
        'ar_1:1',
    ]

    const transformations = generateParamsForProduct().length ? `${generateParamsForProduct().join(',')}/` : ''

    // image source with the query paremeters removed
    const source = (src as string)?.split('?')[0]

    return `${appendTransformation(source, transformations)}`
}

export default CloudinaryLoader
