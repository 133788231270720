import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import app from '@frontastic/catwalk/src/js/app/app';
import { FormattedMessage, injectIntl } from 'react-intl';
import NodeLink from '@frontastic/catwalk/src/js/app/nodeLink';
import Link from '@frontastic/catwalk/src/js/app/link';
import { Cookies, withCookies } from 'react-cookie';
import MediaImage from '@frontastic/catwalk/src/js/mediaImage';
import Sport2000Logo from '../../../atoms/sport2000Logo';
import TopCategories from './TopCategories';
import BottomNavigation from './BottomNavigation';
import Navigation from './Navigation';
import Search from '../../SearchAlgolia/Search';
import { ReactComponent as LogoAbsoluteRun } from '../../../../../icons/sport2000-icons/logos/absoluterun.svg';
import { ArrowRightIcon } from '../../../atoms/icon';

const ABSOLUTE_TEAMSPORT_TOP_CATEGORY = 0;
const RUNNING_EXXPERT_TOP_CATEGORY = 2;

const Mobile = ({
  intl,
  logoImageWithText,
  cartItemsCount,
  category,
  currentTopCategory,
  handleSelectTopCategory,
  placeholderSearch,
  context,
  searchFAQ,
  node,
  cookies,
  searchHitsOpen,
  setSearchHitsOpen,
  themeName,
  absoluteTeamsportBrand,
  runningExpertBrand,
  nbOfCharactersToTriggerSearch,
}) => {
  const ref = useRef(null);
  const [currentOpenedMenu, setCurrentOpenedMenu] = useState(null);
  const [breakScrum, setBreakScrum] = useState([]);
  const [currentActiveItem, setCurrentActiveItem] = useState('');
  const currentTree =
    category[currentTopCategory].customTree ||
    category[currentTopCategory].tree ||
    category[currentTopCategory].children;
  const menuIdSearch = 'search';
  const [currentLogoHref, setCurrentLogoHref] = useState('/');
  const [currentAbsoluteLogo, setCurrentAbsoluteLogo] = useState(null);
  const [currentLogoAltTitle, setCurrentLogoAltTitle] = useState('header.sport2000IconAltTitle');
  const [isCustomLogo, setIsCustomLogo] = useState(false);
  const [categoryBrand, setCategoryBrand] = useState([]);
  const [formatStoreData, setFormatStoreData] = useState(null);

  const [storePage] = useState(app.getApi().router.context.projectConfiguration.storePage);

  useEffect(() => {
    if (cookies?.cookies?.store) {
      setFormatStoreData(JSON.parse(cookies.cookies.store));
    }
  }, [cookies]);

  useEffect(() => {
    setIsCustomLogo(!!themeName);

    switch (themeName) {
      case 'theme-running-expert':
        setCurrentAbsoluteLogo(
          <Sport2000Logo name={'RunningExperts_1line'} alt={'logo Runner Expert'} height={'40px'} />,
        );
        setCurrentLogoHref('/running-experts');

        return setCurrentLogoAltTitle('header.absoluteRunIconAltTitle');

      case 'theme-absolute-teamsport':
        setCurrentAbsoluteLogo(
          <Sport2000Logo name={'AbsoluteTeamsport_1line'} alt={'logo Absolute teamsport'} height={'40px'} />,
        );
        setCurrentLogoHref('/absolute-teamsport');

        return setCurrentLogoAltTitle('header.absoluteTeamsportIconAltTitle');

      case 'theme-absolute-run':
        setCurrentAbsoluteLogo(<LogoAbsoluteRun width={190} height={40} />);
        setCurrentLogoHref('/absolute-run');

        return setCurrentLogoAltTitle('header.absoluteRunIconAltTitle');

      case 'theme-ski-experts':
        setCurrentAbsoluteLogo(<Sport2000Logo name={'SkiExperts_1line'} alt={'logo Ski Experts'} height={'50px'} />);
        setCurrentLogoHref('/ski-experts');

        return setCurrentLogoAltTitle('header.absoluteRunIconAltTitle');

      default:
        setCurrentAbsoluteLogo(
          <MediaImage
            media={logoImageWithText}
            alt={intl.formatMessage({ id: currentLogoAltTitle })}
            title={intl.formatMessage({ id: currentLogoAltTitle })}
            loading={'lazy'}
            decoding={'async'}
          />,
        );
        setCurrentLogoHref('/');

        return setCurrentLogoAltTitle('header.sport2000IconAltTitle');
    }
  }, [currentTree, themeName]);

  useEffect(() => {
    switch (currentTopCategory) {
      case ABSOLUTE_TEAMSPORT_TOP_CATEGORY:
        setCategoryBrand(absoluteTeamsportBrand);
        break;
      case RUNNING_EXXPERT_TOP_CATEGORY:
        setCategoryBrand(runningExpertBrand);
        break;
      default:
        setCategoryBrand(null);
    }
  }, [currentTopCategory]);

  const toggleMenu = (menuId) => {
    if (currentOpenedMenu === menuId) {
      if (currentOpenedMenu === menuIdSearch) {
        document.body.style.removeProperty('top');
        document.body.classList.remove('fixed');
      }

      return setCurrentOpenedMenu(null);
    }

    return setCurrentOpenedMenu(menuId);
  };

  const handleClickOnNavigationItem = (itemId, itemRoute) => {
    if (currentActiveItem === itemId) {
      if (itemRoute) {
        app.getRouter().history.replace('/');
      }

      return setCurrentActiveItem('');
    }

    if (itemRoute && itemRoute.includes('/')) {
      app.getRouter().history.replace(itemRoute);
    }

    if (itemRoute && !itemRoute.includes('/')) {
      app.getRouter().push(itemRoute);
    }

    return setCurrentActiveItem(itemId);
  };

  const handleSelectItem = (e, item) => {
    const tempBreakcrum = breakScrum.concat();
    const NUMBER_CATEGORY_RENDER = 2;

    if (breakScrum.length === NUMBER_CATEGORY_RENDER) {
      tempBreakcrum.shift();
      tempBreakcrum.push(item);
      setBreakScrum(tempBreakcrum);
      return;
    }

    setBreakScrum([...breakScrum, item]);
  };

  const renderBreakScrum = () => {
    if (!breakScrum.length) {
      const topCategory = category[currentTopCategory];

      if (topCategory.key === 'Fussball') {
        return (
          <Link
            path={'/absolute-teamsport'}
            onClick={() => {
              setCurrentOpenedMenu(null);
              setCurrentActiveItem('');
            }}
            className={'active'}
          >
            {topCategory.name}
          </Link>
        );
      }

      if (topCategory.key === 'Running') {
        return (
          <Link
            path={'/running-experts'}
            onClick={() => {
              setCurrentOpenedMenu(null);
              setCurrentActiveItem('');
            }}
            className={'active'}
          >
            {topCategory.name}
          </Link>
        );
      }

      return (
        <Link
          path={topCategory.reference?.target || '/'}
          onClick={() => {
            setCurrentOpenedMenu(null);
            setCurrentActiveItem('');
          }}
          className={'active'}
        >
          {topCategory.name}
        </Link>
      );
    }

    return breakScrum.map((item, i) => {
      const isActiveClass = i === 1 || breakScrum.length === 1;

      if (typeof item === 'string') {
        return (
          <a
            className={classnames({ active: isActiveClass })}
            onClick={() => {
              setCurrentOpenedMenu(null);
              setCurrentActiveItem('');
            }}
          >
            {item}
          </a>
        );
      }

      if (item.nodeId) {
        /// @COFIXME[page-folder](FLBML-114): Replace NodeLink with PageFolderLink?
        return (
          <NodeLink
            key={item.nodeId}
            node={item}
            className={classnames({ active: isActiveClass })}
            onClick={() => {
              setCurrentOpenedMenu(null);
              setCurrentActiveItem('');
            }}
          >
            {item.name}
          </NodeLink>
        );
      }

      if (item.id) {
        return (
          <Link
            path={item.url ? item.url : `/c/${item.id}/${item.slug}`}
            key={item.id}
            className={classnames({ active: isActiveClass })}
            onClick={() => {
              setCurrentOpenedMenu(null);
              setCurrentActiveItem('');
            }}
          >
            {item.name}
          </Link>
        );
      }

      return (
        <a
          key={item.id || item.nodeId}
          className={classnames({ active: isActiveClass })}
          onClick={() => {
            setCurrentOpenedMenu(null);
            setCurrentActiveItem('');
          }}
        >
          {item.name}
        </a>
      );
    });
  };

  const handleBack = () => {
    setBreakScrum((prev) => {
      const newArr = prev.slice();

      newArr.splice(newArr.length - 1, 1);

      return [...newArr];
    });
  };

  return (
    <div className={'header-mobile'}>
      <div className={'header-mobile--logo'}>
        <a ref={ref} href={currentLogoHref}>
          {currentAbsoluteLogo}
        </a>
      </div>

      <BottomNavigation
        cartItemsCount={cartItemsCount}
        toggleMenu={toggleMenu}
        handleClickOnNavigationItem={handleClickOnNavigationItem}
        currentActiveItem={currentActiveItem}
        node={node}
      />

      <div
        className={classnames('header-mobile--navigation', {
          active: currentOpenedMenu === 'menu',
        })}
      >
        <div className={'header-mobile--navigation-top'}>{renderBreakScrum()}</div>

        <Navigation
          currentTree={currentTree}
          categoryBrand={categoryBrand}
          onSelectItem={handleSelectItem}
          onBack={handleBack}
          currentTopCategory={currentTopCategory}
          setCurrentActiveItem={setCurrentActiveItem}
          setCurrentOpenedMenu={setCurrentOpenedMenu}
        />

        <TopCategories
          category={category}
          currentTopCategory={currentTopCategory}
          setCurrentActiveItem={setCurrentActiveItem}
          setCurrentOpenedMenu={setCurrentOpenedMenu}
          handleSelectTopCategory={(event, index) => {
            event.preventDefault();
            if (index !== currentTopCategory) {
              handleSelectTopCategory(index);
              setBreakScrum([]);
            }
          }}
        />
      </div>

      <div
        className={classnames('header-mobile--navigation', {
          active: currentOpenedMenu === 'search' || searchHitsOpen,
          'search-result-mobile active': node && node.nodeType === 'search' && !searchHitsOpen,
        })}
      >
        <Search
          mobileToggleMenu={toggleMenu}
          mobileSetCurrentActiveItem={setCurrentActiveItem}
          mobileSearchMenuOpen={currentOpenedMenu === 'search'}
          placeholderSearch={placeholderSearch}
          context={context}
          searchFAQ={searchFAQ}
          node={node}
          searchHitsOpen={searchHitsOpen}
          setSearchHitsOpen={setSearchHitsOpen}
          categories={category}
          themeName={themeName}
          nbOfCharactersToTriggerSearch={nbOfCharactersToTriggerSearch}
        />
      </div>
      <div
        className={classnames('header-mobile--navigation', {
          active: currentOpenedMenu === 'locations' && formatStoreData,
        })}
      >
        <div className={'store-details-header'}>
          <div className={'store-details-header-flyout'}>
            <div className={'store-details-header-flyout-wrap'}>
              <div className={'mb-2 font-bold'}>
                <FormattedMessage id={'storeDetail.headerMyStore'} />
              </div>
              {!!formatStoreData && (
                <div className={'relative flex'}>
                  <a
                    href={formatStoreData.url}
                    target={'_blank'}
                    rel={'noreferrer'}
                    className={'store-detail-header-flyout-wrap-content'}
                    onClick={() => {
                      setCurrentOpenedMenu(null);
                      setCurrentActiveItem('');
                    }}
                  >
                    <div>
                      <u>{formatStoreData.name}</u>
                      <br />
                      {formatStoreData.street} {formatStoreData.streetNumber}
                      <br />
                      {formatStoreData.postCode} {formatStoreData.city}
                    </div>
                  </a>
                  <ArrowRightIcon width={20} height={20} className={'store-details-header-flyout-wrap-icon'} />
                </div>
              )}
              {/* @COFIXME[page-folder](FLBML-114): Replace NodeLink with PageFolderLink? */}
              <NodeLink
                node={{ nodeId: storePage }}
                onClick={() => {
                  setCurrentOpenedMenu(null);
                  setCurrentActiveItem('');
                }}
                className={'btn btn-default store-details-header-flyout-wrap-content-button mt-4 w-full'}
              >
                <FormattedMessage id={'storeDetail.headerButtonLink'} />
              </NodeLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Mobile.propTypes = {
  logoImageWithText: PropTypes.objectOf(PropTypes.object).isRequired,
  category: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleSelectTopCategory: PropTypes.func.isRequired,
  cartItemsCount: PropTypes.number.isRequired,
  currentTopCategory: PropTypes.number.isRequired,
  placeholderSearch: PropTypes.string,
  searchFAQ: PropTypes.arrayOf(PropTypes.any).isRequired,
  context: PropTypes.object.isRequired,
  node: PropTypes.any,
  intl: PropTypes.any,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
  setSearchHitsOpen: PropTypes.any,
  searchHitsOpen: PropTypes.any,
  themeName: PropTypes.any,
  absoluteTeamsportBrand: PropTypes.any,
  runningExpertBrand: PropTypes.any,
  nbOfCharactersToTriggerSearch: PropTypes.number,
};

export default withCookies(injectIntl(Mobile));
