import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Helmet } from 'react-helmet'
import ComponentInjector from '@frontastic/catwalk/src/js/app/injector'
import { withRouter } from 'next/router';
import { getBaseUrl } from '@frontastic/catwalk/js/helper/getBaseUrl';

class Base extends Component {
    render() {
        const baseLink = getBaseUrl(this.props.router)

        return (
            <Helmet>
                <base href={baseLink} />
            </Helmet>
        )
    }
}

Base.propTypes = {
    node: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
}

Base.defaultProps = {}

export default withRouter(ComponentInjector.return('Node.Meta.Base', Base))
